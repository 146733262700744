// Step 1: Import React
import * as React from "react";
import {Container, Row, Col } from "react-bootstrap";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/bg_sobre-nosotros.jpg";
import Slider from "react-slick";
import IframeVIdeo from "../components/iframe_video/iframe_video";
import { SalWrapper } from "react-sal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./empresa.css";
import SlickBoard from "../components/slick_board/slick_board";
import FlagArg from "../imgs/flag-argentina.svg";
import FlagUruguay from "../imgs/flag-uruguay.svg";
import FlagPanama from "../imgs/flag-panama.svg";
import FlagInglaterra from "../imgs/flag-inglaterra.svg";
import FlagUsa from "../imgs/flag-usa.svg";
import { useState } from "react";
import { useEffect } from "react";
import { apiRequest } from "../apiRquest/apiRequest";
import { Helmet } from "react-helmet";

let text = `<span class="highlightsbold">#SomosBalanz</span> <br/> Somos especialistas en inversiones.`;
const pageTitle = 'Somos Balanz, especialistas en inversiones';
const pageDesc = 'Somos Balanz, especialistas en inversiones con una trayectoria de 20 años en el mercado de capitales local. Contamos con más 200.000 clientes.';


const EmpresaPage = () => {

    const [board, setBoard] = useState([])

    const slickSettings = {
        centerMode: true,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 1,
        dots: true,
        arrows: true
    };

    const slickServiciosSettings = {
        centerMode: false,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 576,
                settings: { centerMode: true, slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };
    let boardList = [
        {
            image: "Claudio-Porcel.png",
            name: "Claudio Porcel",
            position: "Presidente",
            description: "Fundó Balanz Capital en el año 2002. Es Contador Público de la Universidad de Buenos Aires (UBA).",
            text: "Fue miembro del Directorio de BYMA y MAE. Actualmente, es asesor de la Bolsa de Valores de Argentina. Previamente fue Director de Argencard S.A. y del Banco Liniers Sudamericano. También se desempeñó como Portfolio Manager y Analista de Mercados Locales e Internacionales.",
        },
        {
            image: "Isabel-Pita.png",
            name: "Isabel Pita",
            position: "Directora - Socia Accionaria",
            description: "Es Licenciada en Administración de Empresas de la Universidad Católica Argentina (UCA).",
            text: "Cuenta con más de 40 años de trayectoria en el sistema financiero y bancario. Tiene gran experiencia en la implementación de estrategias, coberturas y valuaciones.",
        },
        {
            image: "Octavio-Bernardez.png",
            name: "Octavio Bernardez",
            position: "Director – Socio Accionario",
            description: "Tiene más de 20 años de experiencia en el mercado financiero y bancario.",
            text: "Cuenta con una amplia trayectoria en el arbitraje de bonos, monedas, acciones y futuros. Es experto en opciones y derivados.",
        },
        {
            image: "Juan-Carlos-Bernardez.png",
            name: "Juan Carlos Bernardez",
            position: "Director - Socio Accionario",
            description: "Es uno de los Traders más reconocidos del Mercado de Capitales Argentino.",
            text: "Antes de su incorporación a Balanz construyó su carrera como Trader del Banco Velox, Banco Liniers Sudamericano & Cia Inversora Bursátil. Es especialista en monedas y arbitraje de bonos.",
        },
        {
            image: "Julio-Merlini.png",
            name: "Julio Merlini",
            position: "CEO de Balanz Argentina",
            description: "Es Economista de la Universidad de Buenos Aires (UBA) y cuenta con una Maestría en Finanzas en la Universidad del CEMA.",
            text: "Su carrera comenzó en el Banco Liniers Sudamericano, habiendo desarrollado una amplia trayectoria en el mercado de capitales y en la estructuración de nuevos negocios.",
        },
        {
            image: "Hector-Capriotti.png",
            name: "Héctor Capriotti",
            position: "Director Comercial",
            description: "Es Licenciado en Economía y en Administración de Empresas de la Universidad Católica Argentina (UCA).",
            text: "Cuenta con más de 30 años de carrera en el mercado financiero y bancario. Se desempeñó como Gerente General de Banelco y ocupó diversos cargos jerárquicos locales y regionales en HSBC. Anteriormente, fue Gerente Comercial de Argencard y participó en los directorios de Visa Argentina, Banelco, Link y Veraz.",
        },
        {
            image: "Augusto-Marinozzi.png",
            name: "Augusto Marinozzi",
            position: "Director",
            description: "Es Licenciado en Economía y cuenta con una amplia trayectoria en el Mercado de Capitales.",
            text: "Tiene experiencia liderando áreas de Research y es especialista en asesoramiento y administración de carteras del mercado local, especialmente de inversiones institucionales e individuales.",
        },
        {
            image: "Bruno-de-Maria.png",
            name: "Bruno Demaria",
            position: "Director",
            description: "Es Contador Público de la Universidad de Buenos Aires (UBA) y fue premiado como Mejor CFO del país en 2018.",
            text: "Cuenta con más de 25 años de experiencia en tesorería y finanzas corporativas con alto grado de especialización en la originación y estructuración de transacciones financieras complejas. Fue miembro del directorio de Citibank Argentina, Cargill, Finexcor SRL, etc.",
        },
        {
            image: "Maria-Jose-Gonzalez-Pita.png",
            name: "Maria José González Pita",
            position: "Directora",
            description: "Es Licenciada en Economía empresarial de la Universidad Torcuato Di Tella.",
            text: "Empezó su carrera en Compañía Inversora Bursátil, luego fue parte de la mesa de Alhec Tours y en 2014 se incorporó a Balanz. Cuenta con amplia experiencia como Sales Trader del sector Institucional y de bonos.",
        },
        {
            image: "Cynthia-Petrello.png",
            name: "Cynthia Petrello",
            position: "Directora",
            description: "Es Licenciada en Psicología de la Universidad de Buenos Aires (UBA) y cuenta con un posgrado en Psicoanálisis.",
            text: "Es Directora del área de People en Balanz. Ingresó a la empresa en 2003 y recorrió diversas áreas hasta conformar el departamento de Recursos Humanos en 2010.",
        }

    ];

    useEffect(() => {
        apiRequest('/v1/board')
            .then((res) => {
                let data = []
                res.data.data.forEach(d => {
                    data.push({
                        image: d.image_name,
                        name: d.username,
                        position: d.position,
                        description: d.title,
                        text: d.description,
                    })
                })
                setBoard(data)
            })
    }, [])
    const page = (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "VideoObject",
                            "name": "Somos Balanz",
                            "description": "Lideramos el mercado argentino con una trayectoria de más de 20 años.",
                            "thumbnailUrl": "https://www.youtube.com/watch?v=jnlZrZbp6zg",
                            "uploadDate": "2022-07-08",
                            "duration": "PT0M55S",
                            "contentUrl": "https://balanz.com/empresa/",
                            "embedUrl": "https://balanz.com/empresa/"
                        }
                    `}
                </script>
            </Helmet>
            <ProductBanner
                bannerContainerClassName="bannerAbout"
                bannerTrasparecy="bannerMask"
                productTitle="Sobre Nosotros"
                bannerImage={Banner}
                bannerImageClassName="aboutdBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
                <section className="fullwidth page-description"
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="200"
                >
                    <Container>
                        <Row className="">
                            <Col xs={12} >
                                <div className="py-lg-4 py-md-3 py-2">
                                    <h2 className="as-base-text">
                                        <strong className="primary-color">Lideramos el mercado argentino con una trayectoria de más de 20 años.</strong>
                                    </h2>
                                    <p className="mt-1 base-text">
                                        Hoy nos expandimos en la región y en el mundo. Nuestra estrategia es clara: buscamos siempre la excelencia. Hacemos un research exhaustivo del mercado hasta dar con productos de calidad excepcional.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth somos-balanz">
                    <Container>
                        <Row className="gx-5 py-3 py-md-4 py-lg-5 justify-content-center">
                            <Col xs={12} md={8} className="d-lg-flex justify-content-lg-end align-items-center">
                                <IframeVIdeo
                                    src="https://www.youtube.com/embed/jnlZrZbp6zg"
                                    mobile={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth mision-vision bg-lt-grey">
                    <Container>
                        <Row className="gx-5 justify-content-center">
                            <Col xs={12} md={6} className=""
                                data-sal="slide-up"
                                data-sal-delay="0"
                                data-sal-duration="300"
                            >
                                <h3 className="ash2 mt-1 mt-md-0">Misión</h3>
                                <p className="base-text">Democratizar el mundo de las inversiones, dando acceso a través de la información y la tecnología, para escalar exponencialmente la variedad de productos y clientes locales e internacionales.</p>
                            </Col>

                            <Col xs={12} md={6} className=""
                                data-sal="slide-up"
                                data-sal-delay="100"
                                data-sal-duration="300"
                            >
                                <h3 className="ash2 mt-4 mt-md-0">Visión</h3>
                                <p className="base-text">Comprender que las mejores inversiones siempre acompañaron la pulsión de la humanidad. Recorrer la historia, comprender el presente y reconocer el futuro es nuestra forma de avanzar en el universo de las inversiones y sostener nuestro liderazgo en Argentina y en el mundo.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth servicios">
                    <Container>
                        <Row className="gx-5 py-3 py-md-4 py-lg-5 justify-content-center">
                            <Col xs={12} className="">
                                <p className="base-text"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >Brindamos nuestros servicios a más de 1.000.000 clientes, que confían en nuestro permanente compromiso y solidez en acercar las mejores propuestas de inversión.</p>

                                <p className="base-text"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >Contamos con nuestra propia Sociedad Gerente de Fondos Comunes de Inversión. Gestionamos 30 fondos con estrategias específicas para cada necesidad, y tenemos un patrimonio bajo administración de más de $1.500.000 millones. Estamos entre las primeras 2 Sociedades Gerentes Independientes, siendo la de mayor crecimiento entre sus pares, en el último año.</p>
                                <p className="base-text block-quote"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >Desde 2017, somos uno de los agentes líderes en el ranking BYMA en negociaciones de instrumentos de renta fija y estamos entre los agentes con mayor volumen de operaciones de títulos públicos provinciales y municipales en MAE.</p>

                                <p className="base-text"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >Operamos como Agente de Liquidación y Compensación en los mercados BYMA, MAE, MAV y Rofex. Las especies que se operan con nosotros, son custodiadas por Caja de Valores S.A.</p>
                                <p className="base-text"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >
                                    Somos especialistas en valor y estamos preparados para ayudar a que ese universo infinito se reduzca a un mundo nuevo de inversiones más inteligentes.
                                    Nuestros aliados son:</p>
                                <ul className="triangle-list list-text mt-md-5 mt-3"
                                    data-sal="slide-up"
                                    data-sal-delay="0"
                                    data-sal-duration="200"
                                >
                                    <li>
                                        Nuestro equipo, los especialistas más capacitados.
                                    </li>
                                    <li>
                                        La mejor tecnología, desarrollamos la plataforma más intuitiva y segura del mercado.
                                    </li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth bg-lt-grey board">
                    <Container>
                        <SlickBoard
                            slickSettings={slickSettings}
                            list={board}
                        />
                    </Container>
                </section>
                <section className="fullwidth que-hacemos">
                    <Container>
                        <Row className="gx-5">
                            <h2>¿Qué hacemos?</h2>
                            <Slider className="slick-que-hacemos" {...slickServiciosSettings}>
                            <Col xs={12} md={6} className="slick-item-container" style={{margin: '0 1rem'}}>
                                    <h3 className="block-quote bg-lt-grey p-4">Asset<br />Management</h3>
                                    <ul className="triangle-list list-text mt-md-5 mt-3 highlightsbold">
                                        <li>
                                            Administramos una familia de más de 29 Fondos Comunes de Inversión priorizando la preservación del capital.
                                        </li>
                                        <li>
                                            Contamos con fondos y estrategias de inversión en diferentes activos y monedas.
                                        </li>
                                        <li>
                                            Fondos de renta fija (mercado monetario, deuda corporativa, deuda sub-soberana y PyME).
                                        </li>
                                        <li>
                                            Fondos de renta variable argentina gestionados activamente.
                                        </li>
                                        <li>
                                            Interactuamos con gerentes corporativos y expertos de la industria local.
                                        </li>
                                    </ul>
                                </Col>
                            <Col xs={12} md={6} className="slick-item-container" style={{margin: '0 1rem'}}>
                                    <h3 className="block-quote bg-lt-grey p-4">Sales and<br />Trading</h3>
                                    <ul className="triangle-list list-text mt-md-5 mt-3 highlightsbold">
                                        <li>
                                            Plataforma para operar con soluciones para clientes corporativos e institucionales.
                                        </li>
                                        <li>
                                            Servicio de brokerage en las principales clases de activos (renta fija, renta variable futuros y FCI).
                                        </li>
                                        <li>
                                            Amplia cobertura de activos soberanos, sub-soberanos, municipales y corporativos.
                                        </li>
                                    </ul>
                                </Col>
                            <Col xs={12} md={6} className="slick-item-container" style={{margin: '0 1rem'}}>
                                    <h3 className="block-quote bg-lt-grey p-4">Mercado de capitales y<br />finanzas corporativas</h3>
                                    <ul className="triangle-list list-text mt-md-5 mt-3 highlightsbold">
                                        <li>
                                            Participación local e internacional.
                                        </li>
                                        <li>
                                            Asesoramos y diseñamos la estrategia de financiamiento para entidades públicas y privadas.
                                        </li>
                                        <li>
                                            Contamos con una sólida base de clientes y una amplia red de distribución en el país y en el exterior.
                                        </li>
                                        <li>
                                            Valuación de empresas.
                                        </li>
                                    </ul>
                                </Col>
                            <Col xs={12} md={6} className="slick-item-container" style={{margin: '0 1rem'}}>
                                    <h3 className="block-quote bg-lt-grey p-4">Research<br/>&nbsp;</h3>
                                    <ul className="triangle-list list-text mt-md-5 mt-3 highlightsbold">
                                        <li>
                                            Análisis de escenarios globales y locales con riesgos políticos y macroeconómicos y proyección de sus variables.
                                        </li>
                                        <li>
                                            Información sobre industrias, mercados y tendencias con un análisis exhaustivo de las empresas y el contexto.
                                        </li>
                                        <li>
                                            Asesoramiento técnico a fondos internacionales de primera linea.
                                        </li>
                                        <li>
                                            Elaboración de modelos de renta fija.
                                        </li>
                                        <li>
                                            Monitoreo y análisis de créditos corporativos.
                                        </li>
                                        <li>
                                            Seguimiento continuo del universo de deuda soberano y sub-soberano.
                                        </li>
                                    </ul>
                                </Col>
                            <Col xs={12} md={6} className="slick-item-container" style={{margin: '0 1rem'}}>
                                    <h3 className="block-quote bg-lt-grey p-4">Wealth<br />Management</h3>
                                    <ul className="triangle-list list-text mt-md-5 mt-3 highlightsbold">
                                        <li>
                                            Brindamos variedad de alternativas de inversión por cuenta propia y por intermedio de entidades del exterior.
                                        </li>
                                        <li>
                                            Atención personalizada de asesores altamente capacitados en el mercado de capitales locales e internacional.
                                        </li>
                                        <li>
                                            Amplia base de acuerdos con instituciones financieras internacionales de primera línea.
                                        </li>
                                        <li>
                                            Generamos carteras a medida para diferentes perfiles de inversión.
                                        </li>
                                        <li>
                                            Realizamos curadurías de fondos y Trading Ideas para invertir en instrumentos de inversión focalizados en temas novedosos.
                                        </li>
                                        
                                    </ul>
                                </Col>
                            </Slider>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth internacional">
                    <Container>
                        <Row className="full-investment-house d-flex justify-content-start">
                            <Col xs={12} md={6}>
                                <h2>Balanz Internacional</h2>
                                <p className="base-text bg-lt-grey p-4">Somos una Full Investment House con alcance mundial. Contamos con oficinas en 5 países y más de 1300 empleados. </p>
                                <p className="base-text p-4">Una presencia global es esencial para
                                    comprender y responder frente a las
                                    necesidades de nuestros clientes.</p>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-start py-4">
                            <Col xs={12} md={6}>
                                <div className="grid-paises d-grid shadow">
                                    <div className="country">
                                        <img src={FlagArg} alt="Argentina" className="country-flag" />
                                        <div>
                                            <strong>Argentina</strong><br /> Buenos Aires
                                        </div>
                                    </div>
                                    <div className="country">
                                        <img src={FlagUsa} alt="USA" className="country-flag" />
                                        <div>
                                            <strong>Estados Unidos</strong><br />Miami</div>
                                    </div>
                                    <div className="country">
                                        <a href="https://balanz.com.uy/" target="_blank">
                                            <img src={FlagUruguay} alt="Uruguay" className="country-flag" />
                                        </a>
                                        <a href="https://balanz.com.uy/" target="_blank">
                                            <div>
                                                <strong>Uruguay</strong><br />Montevideo</div>
                                        </a>
                                    </div>
                                    <div className="country">
                                        <a href="https://www.balanz.com/international" target="_blank">
                                            <img src={FlagInglaterra} alt="Inglaterra" className="country-flag" />
                                        </a>
                                        <div>
                                            <a href="https://www.balanz.com/international" target="_blank">
                                                <strong>Inglaterra</strong><br />Londres</a>
                                        </div>
                                    </div>
                                    <div className="country">
                                        <img src={FlagPanama} alt="Panamá" className="country-flag" />
                                        <div>
                                            <strong>Panamá</strong><br />Ciudad de Panamá</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </SalWrapper>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"empresa"}></Layout>
        </main>
    );
};


// Step 3: Export your component
export default EmpresaPage;